<template>
  <div class="gameWallet">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">游戏钱包</div>
      <div class="rightBtn"></div>
    </div>
    <div class="mainContent">
      <div class="balanceTitle">游戏余额</div>
      <div class="balanceBox">
        <svg-icon class="gameGold" iconClass="game_gold"></svg-icon>
        <div class="balance">{{ balance }}</div>
      </div>
      <div class="buttonBox">
        <router-link tag="div" to="/gameWithdraw" class="billBtn">提现</router-link>
        <router-link tag="div" to="/billDetails?t=game" class="billBtn">账单</router-link>
      </div>
      <div class="line"></div>
      <div class="buyGoldTitle">购买游戏币</div>
      <div class="amountBox">
        <div
          :class="selectIndex === index ? 'activeAmount' : 'amountItem'"
          v-for="(item, index) in amountList"
          :key="item.id"
          @click="selectAmount(index)"
        >
          <div class="amont_box">
            <div class="realAmount">¥{{ item.money ? item.money / 100 : 0 }}</div>
            <div class="amount">
              <svg-icon class="gold" iconClass="gold"></svg-icon>
              {{ item.amount || 0 }}
            </div>
          </div>
          <!-- <div class="desc" v-if="item.firstGiveGold > 0">首充送{{item.firstGiveGold}}金币</div> -->
          <div class="desc" v-if="item.couponDesc">{{ item.couponDesc }}</div>
        </div>
      </div>
      <div class="btnBox">
        <div class="rechargeBtn" @click="showPay">立即充值</div>
        <div class="tip">
          支付中如有问题，请联系
          <router-link tag="span" to="/kefu">在线客服</router-link>
        </div>
      </div>
    </div>

    <!-- 支付方式弹窗 -->
    <van-popup class="rechargePop" v-model="isRecharge" position="bottom">
      <div class="rechargeTitle">请选择支付方式</div>
      <van-radio-group v-model="activeItem" direction="horizontal" class="rechargeList">
        <div v-for="item in payMethods" :key="item.productID" @click.stop="selectPay(item)">
          <div class="rechargeItem" v-if="item.type !== 'daichong'">
            <svg-icon class="payIcon" :iconClass="item.type"></svg-icon>
            <div class="payText">{{ item.typeName }}</div>
            <van-radio checked-color="#fe7f0f" icon-size="16px" :name="item.type"></van-radio>
          </div>
          <!-- 代充 -->
          <div v-if="dcInfo.traders && dcInfo.traders.length > 0 && item.type === 'daichong'">
            <div v-for="dcItem in dcInfo.traders[0].payInfos" :key="dcItem.payMethod" @click="selectDcPay(item, dcItem)">
              <div class="rechargeItem" v-if="item.type == 'daichong'">
                <svg-icon class="payIcon" :iconClass="getSvgPayIcon(dcItem.payMethod)"></svg-icon>
                <div class="payText">
                  {{ getPayName(dcItem.payMethod) }}
                  <span>（官方推荐）</span>
                </div>
                <van-radio checked-color="#fe7f0f" icon-size="16px" :name="'dc' + dcItem.payMethod"></van-radio>
              </div>
            </div>
          </div>
        </div>
      </van-radio-group>
      <div class="payTipText">支付小贴士</div>
      <div class="payTip">1、跳转后请及时付款，超时支付无法到账，要重新发起</div>
      <div class="payTip">2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单</div>
      <div class="customerService">支付遇到问题联系客服</div>
      <div class="payBtn" @click="toPay">立即支付</div>
      <div class=""></div>
    </van-popup>

    <!-- 支付提示 -->
    <van-popup class="kindTips" v-model="showKindTips">
      <div class="kindTipsBox">
        <div class="kindTipTitle">温馨提示</div>
        <div class="redTip">软妹社收入来源于广告及赞助商</div>
        <div class="redTip mb16">产品安全无毒，可放心使用！</div>
        <div class="kTip">由于APP包含成人敏感内容，</div>
        <div class="kTip">会被潘蔚恶意软件。</div>
        <div class="kTip">如在支付过程中遇到恶意软件提醒，</div>
        <div class="kTip">
          请
          <span>点击忽略</span>
          即可。
        </div>

        <div class="tipImg"></div>

        <div class="cBtn" @click="comfinPay">确认支付</div>
      </div>
    </van-popup>

    <!-- 订单确认弹窗 -->
    <van-popup class="payTipPop" v-model="showPayTip">
      <svg-icon class="payTipLogo" iconClass="payTipLogo"></svg-icon>
      <div class="tipTitle">订单支付确认</div>
      <div class="tipItem">
        1.支付成功后，一般会在
        <span>1～10分钟到账</span>
        ，如超时未到账，请联系在线客服为你处理。
      </div>
      <div class="tipItem">2.受特殊行业限制，如支付失败可尝试重新发起订单，系统会自动为您处理。</div>
      <div class="tipItem">3.本APP有隐定的广告收益，产品稳定安全可放心使用。</div>
      <div class="confirmBtn" @click="showPayTip = false">支付成功</div>
      <router-link tag="div" to="/kefu" class="kefu">支付遇到问题？</router-link>
    </van-popup>
  </div>
</template>

<script>
import { gameCurrency, gameBalance } from '@/api/game';
import { Popup, RadioGroup, Radio, Toast } from 'vant';
import { getLocalItem } from '@/utils/longStorage';
import { getPay } from '@/api/mine';
export default {
  components: {
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  data() {
    return {
      balance: 0,
      selectIndex: 0,
      amountList: [],
      payMethods: [],
      dcInfo: {},
      isRecharge: false,
      activeItem: {},
      payInfo: {},
      rchgType: {},
      dcPayInfo: {},
      payMethodType: 'dc', // 支付方式类型  支付渠道-channel  代充-dc
      showKindTips: false,
      showPayTip: false,
    };
  },
  created() {
    this.queryGameBalance();
    this.queryAmountList();
  },
  methods: {
    async queryAmountList() {
      let req = {
        type: '2',
      };
      // alert(req)
      let ret = await this.$Api(gameCurrency, req);
      if (ret && ret.code == 200) {
        // console.log(ret.data)
        this.amountList = ret.data.list || [];
        this.dcInfo = JSON.parse(JSON.stringify(ret.data.daichong));
        this.selectAmount(0);
      }
      this.$store.commit('app/SET_LOADING', false);
    },
    // 获取游戏余额
    async queryGameBalance() {
      this.$store.commit('app/SET_LOADING', true);
      let ret = await this.$Api(gameBalance);
      if (ret && ret.code == 200) {
        this.balance = ret.data.wlBalance || 0;
      }
    },
    // 选择金额
    selectAmount(index) {
      this.selectIndex = index;
      this.payMethods = [...this.amountList[index].rchgType];
      let dcArr = this.payMethods.filter((i) => {
        return i.type == 'daichong';
      });
      let channel = this.payMethods.filter((i) => {
        return i.type != 'daichong';
      });
      if (channel.length > 0) {
        this.selectPay(this.payMethods[0]);
      } else if (dcArr.length > 0 && this.dcInfo.traders.length > 0 && this.dcInfo.traders[0].payInfos.length > 0) {
        let arr = this.payMethods.filter((i) => {
          return i.type == 'daichong';
        });
        this.selectDcPay(arr[0], this.dcInfo.traders[0].payInfos[0]);
      }
    },
    showPay() {
      this.isRecharge = true;
    },
    toPay() {
      this.showKindTips = true;
    },
    // 选中支付方式
    selectPay(item) {
      if (item.type != 'daichong') {
        this.payInfo = item;
        this.rchgType = {};
        this.dcPayInfo = {};
        this.payMethodType = 'channel';
        this.activeItem = item.type;
      }
    },
    // 选中代充支付方式
    selectDcPay(rchgType, dcPayInfo) {
      this.payInfo = {};
      this.rchgType = rchgType;
      this.dcPayInfo = dcPayInfo;
      this.payMethodType = 'dc';
      this.activeItem = 'dc' + this.dcPayInfo.payMethod;
    },
    comfinPay() {
      if (this.payMethodType == 'channel') {
        this.channelPay();
      } else if (this.payMethodType == 'dc') {
        this.dcToPay();
      }
    },
    // 支付渠道支付
    async channelPay() {
      this.$store.commit('app/SET_LOADING', true);

      let req = {
        uid: this.$store.getters.userInfo.uid,
        rechargeType: this.payInfo.type,
        productID: this.amountList[this.selectIndex].id,
        buyType: 2, // 1是金币 2是游戏币 3是果币 4是商品
      };
      try {
        let ret = await this.$Api(getPay, req);
        this.payInfo = {};
        this.$store.commit('app/SET_LOADING', false);
        if (ret && ret.code === 200) {
          this.showPayTip = true;
          this.showKindTips = false;
          setTimeout(() => {
            window.location = ret.data.payUrl;
            this.$store.dispatch('user/getUserInfo');
          }, 200);
        } else {
          Toast(ret.tip || '充值失败, 请稍后再试');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('充值失败, 请稍后再试');
      }
    },
    // 代充
    dcToPay() {
      // let userInfo = this.$store.getters.userInfo;
      let payMoney = this.amountList[this.selectIndex].money;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(this.dcPayInfo)); // 代充支付类型
      // console.log(payInfoModel)
      let channel = this.rchgType.channel;
      let token = getLocalItem('token');
      let productInfo = {
        id: this.amountList[this.selectIndex].id,
        type: 0, /// 0购买金币 1是会员 2是果币  3是裸聊
        version: '3.0.0',
      };
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (payInfoModel.payType.includes(2) && payInfoModel.payType.includes(3)) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }

      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = channel;
      let models = JSON.stringify(dcData);
      // console.log(dcData)
      let payUrl = `${channel}/?data=${this.encodeBase64(models)}&token=${token}`;
      this.showPayTips = true;
      this.showPayTip = true;
      this.showKindTips = false;
      window.location = payUrl;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, 'utf-8').toString('base64');
      return base64Str;
    },
    // 获取支付方式名称
    getPayName(type) {
      switch (type) {
        case 'alipay':
          return '支付宝';
        case 'wechat':
          return '微信';
        case 'union':
          return '银联';
        case 101:
          return '支付宝';
        case 102:
          return '微信';
        case 103:
          return '银行卡';
        case 104:
          return '信用卡';
        case 105:
          return '花呗';
        case 106:
          return '云闪付';
        case 107:
          return 'QQ钱包';
        case 108:
          return '京东支付';
        default:
          return '金币兑换';
      }
    },
    // 获取支付方式图标
    getSvgPayIcon(type) {
      switch (type) {
        case 'alipay':
          return 'alipay';
        case 'wechat':
          return 'wechat';
        case 'union':
          return 'bankcard';
        case 101:
          return 'alipay';
        case 102:
          return 'wechat';
        case 103:
          return 'bankcard';
        case 104:
          return 'bankcard';
        case 105:
          return 'alipay';
        case 106:
          return 'bankcard';
        case 107:
          return 'bankcard';
        case 108:
          return 'bankcard';
        default:
          return 'gold';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gameWallet {
  height: 100%;
  overflow-y: auto;
  // white-space: pre-wrap;
  -webkit-overflow-scrolling: touch;
  .navBar {
    box-sizing: border-box;
    height: 46px;
    // margin-bottom: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .leftBtn {
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 20px;
    }

    .rightBtn {
      width: 24px;
      height: 24px;
    }
  }

  .mainContent {
    padding: 0 16px;

    .balanceTitle {
      margin-top: 17px;
      font-size: 18px;
    }

    .balanceBox {
      display: flex;
      align-items: center;
      font-size: 38px;
      margin-top: 14px;

      .gameGold {
        // width: 46px;
        // height: 46px;
        font-size: 34px;
        margin-right: 6px;
      }

      .balance {
      }
    }

    .buttonBox {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .billBtn {
      color: #000;
      width: 94px;
      height: 36px;
      font-size: 18px;
      border-radius: 36px;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(180deg, #f1dcb9 0%, #ffcc6e 100%);
    }

    .line {
      height: 3px;
      margin-top: 14px;
      background-image: linear-gradient(90deg, rgba(22, 24, 69, 0) 0%, #ffffff60 51%, rgba(21, 23, 66, 0) 100%);
    }

    .buyGoldTitle {
      font-size: 18px;
      margin-top: 18px;
    }

    .amountBox {
      margin-top: 20px;
      // margin-bottom: 140px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5px;
      // grid-column-gap: 9px;
      // grid-row-gap: 10px;
      grid-auto-flow: row;
      padding-left: 1px;
      box-sizing: border-box;

      .activeAmount {
        width: 122px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 4px;
        // background: #d17e21;
        text-align: center;
        border: solid 2px transparent;
        background-image: linear-gradient(to bottom, #3b341f, #2b2b2b), linear-gradient(to bottom, #f4d88a, #d6be7e60, #c3ae7630);
        background-origin: border-box;
        background-clip: content-box, border-box;
        overflow: hidden;

        .amont_box {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          // justify-content: space-around;
          .realAmount {
            // margin-top: 6.2px;
            width: 100%;
            font-size: 15px;
            color: #958658;
          }

          .amount {
            width: 100%;
            font-size: 22px;
            color: #f1d589;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;

            .gold {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }

        .desc {
          width: 100%;
          height: 19px;
          line-height: 19px;
          font-size: 13px;
          color: #f1d589;
          background: #484233;
          text-align: center;
        }

        // .realAmount {
        //   font-size: 15px;
        //   color: #fff;
        // }

        // .amount {
        //   font-size: 17px;
        //   display: flex;
        //   align-items: center;
        //   font-weight: 600;
        //   .gold {
        //     width: 16px;
        //     height: 16px;
        //     margin-right: 4px;
        //   }
        // }
      }

      .amountItem {
        width: 124px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        // justify-content: center;
        overflow: hidden;
        border-radius: 4px;
        border: 2px solid #5c5c5c44;
        text-align: center;
        opacity: 0.7;

        .amont_box {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          // justify-content: space-around;
          .realAmount {
            // margin-top: 6.2px;
            width: 100%;
            font-size: 15px;
            color: #848484;
          }

          .amount {
            width: 100%;
            font-size: 22px;
            color: #848484;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;

            .gold {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }

        .desc {
          width: 100%;
          height: 19px;
          line-height: 19px;
          font-size: 13px;
          color: #787878;
          background: #333333;
          text-align: center;
        }
      }
    }

    .btnBox {
      width: 100%;
      margin: 50px 0;

      .rechargeBtn {
        width: 228px;
        height: 44px;
        line-height: 44px;
        border-radius: 44px;
        margin: 0 auto;
        text-align: center;
        background-image: linear-gradient(to bottom, #d17e21, #d69626);
      }

      .tip {
        font-size: 12px;
        margin-top: 14px;
        // margin-bottom: 49px;
        color: #e3e3e360;
        text-align: center;

        span {
          color: #fff;
        }
      }
    }
  }

  .rechargePop {
    border-radius: 17px 17px 0 0;
    background-image: linear-gradient(to bottom, #ffebd9, #ffffff);

    .rechargeTitle {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
      color: #00000090;
      border: 1px solid #e5ded3;
    }

    .rechargeList {
      max-height: 310px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 32px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .rechargeItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #151515;
      margin-bottom: 16px;

      .payIcon {
        width: 40px;
        height: 40px;
      }

      .payText {
        flex: 2;
        margin: 0 20px;
        font-size: 18px;
        > span {
          color: red;
        }
      }
    }

    .payTipText {
      font-size: 14px;
      color: #ff760090;
      margin: 0 40px;
    }

    .payTip {
      margin: 0 40px;
      font-size: 14px;
      color: #a3a3a3;
      margin-top: 5px;
    }

    .customerService {
      font-size: 12px;
      text-align: center;
      margin: 24px 0 14px;
      color: #fe7f0f;
    }

    .payBtn {
      width: 228px;
      height: 46px;
      line-height: 46px;
      border-radius: 46px;
      font-size: 16px;
      margin: 0 auto 35px;
      text-align: center;
      background: rgb(238, 133, 54);
    }
  }
  .kindTips {
    border-radius: 14px;
    .kindTipsBox {
      width: 314px;
      padding: 18px 27px 32px;
      border-radius: 10px;
      box-sizing: border-box;
      background-image: linear-gradient(to bottom, #ffebd9, #ffffff);
      text-align: center;
      .kindTipTitle {
        font-size: 27px;
        margin-bottom: 15px;
        color: #000;
      }
      .redTip {
        color: red;
        font-size: 14px;
      }
      .kTip {
        color: #000;
        margin-bottom: 4px;
        font-size: 14px;
        span {
          color: red;
        }
      }
      .tipImg {
        width: 260px;
        height: 116px;
        background: url('../../../assets/png/payTip.png');
        background-size: 100% 100%;
        margin-top: 36px;
      }
      .cBtn {
        width: 100%;
        height: 44px;
        border-radius: 22px;
        line-height: 44px;
        font-size: 16px;
        margin: 30px auto 0;
        text-align: center;
        background: rgb(238, 133, 54);
      }
    }
  }
  .payTipPop {
    overflow: visible;
    width: 340px;
    height: 440px;
    border-radius: 14px;
    // background-image: url('../../../assets/png/payTipBg.png');
    // background-size: 100% 100%;
    background-image: linear-gradient(to bottom, #ffebd9, #ffffff);
    padding: 0 25px;
    box-sizing: border-box;

    .tipTitle {
      font-size: 22px;
      color: #ff9f19;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 20px;
    }
    .tipItem {
      font-size: 14px;
      margin-bottom: 16px;
      color: #425453;
      span {
        color: #ff9f19;
      }
    }
    .confirmBtn {
      width: 252px;
      height: 44px;
      border-radius: 6px;
      line-height: 44px;
      font-size: 16px;
      margin: 30px auto 0;
      text-align: center;
      background: rgb(238, 133, 54);
    }
    .kefu {
      color: #425453;
      text-align: center;
      font-size: 14px;
      margin-top: 12px;
      text-decoration: underline;
    }
  }
  .payTipLogo {
    width: 95px;
    height: 75px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%);
  }
  .mb16 {
    margin-bottom: 16px;
  }
}
</style>
